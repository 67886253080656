import { BaseModel } from 'modules/core/models/base-model';

export class UserEntityTeamLot extends BaseModel {

    entity_id                      = null;
    team_id                        = null;
    lot_intervention_id            = null;
    identifier                     = null;
    user_entity_team_lot_status_id = null;
    use_extra_permission_id        = null;
    roles                          = [];
}
