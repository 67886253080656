import { BaseModel } from 'modules/core/models/base-model';

const ROOT_USER_ID = 1;

export class User extends BaseModel {

    static get ROOT_USER_ID() {
        return ROOT_USER_ID;
    }

    acronym                   = null;
    number                    = null;
    name                      = null;
    email                     = null;
    password                  = null;
    password_confirmation     = null;
    status_id                 = null;
    receive_user_updates      = null;
    roles                     = [];
    permissions               = [];
    entity_team_lot           = {};
    selected_lot_intervention = {};

}
