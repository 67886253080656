import { bindable, inject }  from 'aurelia-framework';
import { BaseListViewModel } from 'base-list-view-model';
import { FilterFormSchema }  from 'modules/administration/roles/filter-form-schema';
import { AppContainer }      from 'resources/services/app-container';
import { RolesRepository }   from './services/repository';

@inject(AppContainer, RolesRepository, FilterFormSchema)
export class ListRoles extends BaseListViewModel {

    listingId = 'administration-roles-listing';

    @bindable headerTitle    = 'listing.administration.roles';
    @bindable newRecordRoute = 'administration.roles.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     */
    constructor(appContainer, repository, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'administration.roles.manage',
            'administration.roles.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.administration.role',
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.roles.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['administration.roles.manage', 'administration.roles.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('administration.roles.edit', { id: row.id }),
                visible: (row) => !row.isSticky && this.appContainer.authenticatedUser.can(['administration.roles.manage', 'administration.roles.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => !row.isSticky && this.appContainer.authenticatedUser.can(['administration.roles.manage', 'administration.roles.delete']),
            },
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      false,
            destroySelected: false,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'name',
                    name:  'roles.name',
                    title: 'form.field.name',
                },
                {
                    data:  'slug',
                    name:  'roles.slug',
                    title: 'form.field.slug',
                },
                {
                    data:  'type',
                    name:  'role_type_translations.name',
                    title: 'form.field.type',
                },
                {
                    data:  'description',
                    name:  'roles.description',
                    title: 'form.field.description',
                },
                {
                    data:  'status',
                    name:  'boolean_statuses.name',
                    searchable: false,
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

}
